.list {
    display: flex;
    flex-direction: column;
    z-index: 100;
    width: 90%;
    max-width: 60rem;
    height: auto;
    box-sizing: border-box;
}

.completedActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}