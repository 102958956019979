.sidebar {
    width: 16rem;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-right: 1rem;
}

.verticalSpacer {
    height: 2rem;
    width: 100%;
}

.todo {
    color: inherit;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    height: 3rem;
    flex-shrink: 0;
    padding: auto;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    box-shadow: inset 0px -2px rgb(186, 186, 186);
}
    .todo:hover {
        filter: brightness(0.9);
        transform: translateY(-3px);
    }
    .todo:active {
        transform: translateY(2px);
        box-shadow: inset 0px -2px rgb(186, 186, 186);
    }

.todoActive {
    background-color:#004FFF;
    color: white;
    box-shadow: inset 0px -2px rgb(186, 186, 186);
}

.noteLink {
    text-decoration: none;
    color: #022c87;
    font-weight: 500;
    width: 100%;
    padding: 0.3rem;
    padding-left: 0.4rem;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
    .noteLink:hover {
        /* transform: translateY(-3px); */
        background-color: #022c8720;
    }


.noteLinkActive {
    /* color: rgb(30, 30, 30); */
    font-weight: bold;
    background-color: #022c8715;
}