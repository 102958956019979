
.liclass {
    /* display: flex;
    justify-content: space-between; */
    position: relative;
    cursor: default;
    border-radius: 1rem;
    margin: 0;
    height: auto;
}

.lileft {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
}

.liright {
    position: absolute;
    display: flex;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    justify-content: right;
    z-index: 300;
}
