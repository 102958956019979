.wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  overscroll-behavior: none;
  /* padding: 2rem 0rem; */
  box-sizing: border-box;
  display: flex;
}

.sidebar {
  /* position: fixed; */
  background-color: beige;
  width: 20rem;
  height: 100%;
  padding: 1rem;
}

.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical-spacer {
  padding: 1rem 0rem;
}

.content-card {
  background-color: #fff;
  align-self: center;
  max-width: 50rem;
  width: 85%;
}

.tab-card {
  align-self: center;
  background-color: #fff;
  width: 50%;
  max-width: 30rem;
}

.folder-tab {
  background-color: #fff;
  text-transform: none;
}